<template>
  <div>
    <b-card>
      <b-card-header
        class="subject-details-card__header position-relative"
        header-class="p-0 mb-2"
      >
        <div class="subject-details-card__title-container d-flex">
          <unicon name="books" width="22" height="22" />
          <h4 class="subject-details-card__title ml-1 mb-0">تفاصيل المادة</h4>
        </div>
        <div
          class="subject-details-card__subject-status-container d-flex align-items-center"
        >
          <div class="subject-details-card__subject-status-title d-flex">
            <span>
              عدد الطلاب :
              <b-badge variant="success"> {{ subjectDto.studentsCount }}</b-badge>
            </span>
          </div>
        </div>
      </b-card-header>
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12" md="6" lg="4">
                <ek-input-text name="name" label=" اسم المادة " v-model="subjectDto.name">
                </ek-input-text>
                <ek-input-text
                  readonly
                  name="عدد الوحدات"
                  label="عدد الوحدات"
                  v-model="subjectDto.unitesCount"
                ></ek-input-text>
                <ek-input-image
                  name=" file"
                  label=" صورة المادة"
                  :image="subjectDto.url"
                  @input="getFile($event)"
                >
                  <h5>انقر لتحميل صورة المادة</h5>
                </ek-input-image>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <ek-input-select
                  :rules="[
                    {
                      type: 'required',
                      message: '  يرجى اختيار الصف',
                    },
                  ]"
                  :options="classes"
                  name="classes"
                  placeholder="اختر الصف "
                  label=" الصف"
                  v-model="subjectDto.classId"
                ></ek-input-select>

                <ek-input-text
                  readonly
                  name=" dateCreated"
                  label="تاريخ الإضافة"
                  :value="formDate(subjectDto.dateCreated)"
                ></ek-input-text>

                <ek-input-image
                  name=" file"
                  label="ايقونة المادة"
                  :image="subjectDto.iconUrl"
                  @input="getImageIcon($event)"
                >
                  <h5>انقر لتحميل ايقونة المادة</h5>
                </ek-input-image>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <ek-input-select
                  :options="[
                    { name: 'مخصص', value: ['', ''] },
                    ...colorList.map((item, index) => ({
                      name: `${item}`,
                      value: item,
                    })),
                  ]"
                  v-model="subjectDto.colors"
                  placeholder="اختر او خصص لون معين"
                  valueLabel="value"
                  name=" اختر لون"
                  label="اللون"
                  :rules="[
                    {
                      type: 'required',
                      message: 'اللون مطلوب',
                    },
                  ]"
                >
                  <template #option="value">
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div class="w-25 d-flex justify-content-between align-items-center">
                        <div
                          id="color"
                          :style="`background-color: ${value.label.split(',')[0]}`"
                        ></div>
                        <div
                          id="color"
                          :style="`background-color: ${value.label.split(',')[1]}`"
                        ></div>
                      </div>
                      <span> {{ value.label }}</span>
                    </div>
                  </template>
                </ek-input-select>
                <b-form-group
                  label="اللون الاول"
                  label-for="اللون"
                  v-if="subjectDto.colors[0] == ''"
                >
                  <b-form-input
                    type="color"
                    name="اللون"
                    v-model="subjectDto.colors[0]"
                  />
                </b-form-group>

                <b-form-group
                  label="اللون الثاني"
                  label-for="اللون"
                  v-if="subjectDto.colors[1] == ''"
                >
                  <b-form-input
                    type="color"
                    name="اللون"
                    v-model="subjectDto.colors[1]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #footer>
        <div class="w-100 d-flex justify-content-between">
          <div>
            <b-button variant="primary" @click="submit">تعديل</b-button>
            <b-button variant="outline-primary " class="mx-1" @click="$router.go(-1)"
              >تراجع</b-button
            >
          </div>
        </div>
      </template>
    </b-card>
    <ek-table
      :columns="headerDetails"
      no_delete
      @details="goToDetails"
      :items="subjectDto.unites"
    >
      <template #table-header>
        <div class="d-flex p-1">
          <unicon class="pr-1" fill="gray" name="bookmark"></unicon>
          <h3>وحدات المادة</h3>
        </div>
        <div>
          <span>
            عدد الوحدات :
            <b-badge variant="success"> {{ subjectDto.unitesCount }}</b-badge>
          </span>
        </div>
      </template>

      <template slot="items.dateCreated" slot-scope="{ value }">
        {{ value ? new Date(value).toLocaleDateString() : "_" }}
      </template>
    </ek-table>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { nullGuid } from "@Ekcore/util/global.js";

export default {
  props: {
    id: String,
  },
  data: () => ({
    nullGuid,
  }),
  computed: {
    ...mapState({
      subjectDto: (state) => state.subjects.subjectDto,
      educationLevelList: (state) => state.subjects.educationLevelList,
      semesterList: (state) => state.subjects.semesterList,
      classes: (state) => state.classes.classesOptions,
      headerDetails: (state) => state.subjects.headerDetails,
      colorList: (state) => state.settings.settingDto.colors,
    }),
  },
  methods: {
    ...mapActions([
      "getSubjectDetails",
      "updateSubject",
      "deleteSubject",
      "getClassesById",
    ]),
    formDate(dateCreated) {
      let dete = `${new Date(dateCreated).toLocaleDateString()}`;
      return dete;
    },
    submit() {
      this.$refs["form"].validate().then((suc) => {
        if (suc) {
          this.updateSubject(this.subjectDto);
        }
      });
    },
    getImageIcon($event) {
      this.subjectDto.iconFile = $event.file;
    },
    getFile($event) {
      this.subjectDto.file = $event.file;
    },
    goToDetails({ row }) {
      this.$router.push(`/admin/units/${row.id}`);
    },
  },
  beforeDestroy() {
    this.$store.commit("Set_Subjects_Dto");
  },
  created() {
    this.getSubjectDetails(this.id);
  },
};
</script>
